import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    showIf: ["true"]
  };

  static targets = [
    "container"
  ];

  connect() { }

  handleChange(e) {
    let result = e.target.value;

    if (e.target.checked) {
      this.containerTarget.classList.remove("hidden");
    } else if (this.showIfValue.includes(result)) {
      this.containerTarget.classList.remove("hidden");
    } else {
      this.containerTarget.classList.add("hidden");
    }

    this.containerTarget.querySelectorAll("input").forEach((input) => {
      input.value = "";
    });
  }
}
