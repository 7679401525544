import SelectController from "./select_controller";
export default class extends SelectController {
  static values = {
    collection: Array,
  };

  connect() {
    $(this.element).select2({
      width: "100%",
      theme: "bootstrap-5",
      templateResult: this.square,
    });

    const fireChangeEvent = () =>
      this.element.dispatchEvent(new Event("change"));

    const unSelectEvent = () => this.element.dispatchEvent(new Event("change"));

    $(this.element).on("select2:select", fireChangeEvent);
    $(this.element).on("select2:unselecting", unSelectEvent);
  }

  /*
    Tailwind Compile Colors
    bg-red-400
    bg-orange-400
    bg-indigo-400
    bg-amber-400
    bg-lime-400
    bg-blue-400
    bg-indigo-400
    bg-slate-400
    bg-violet-400
  */

  square(color) {
    if (color.id) {
      const result = $(
        `<div class="flex items-center border-b pt-1 pb-3 text-capitalize"><div class="mr-2 h-[20px] w-[20px] bg-${color.id}-400"></div>${color.id}</div>`
      );
      return result;
    }
  }
}
