import Rails from "@rails/ujs";
import { Modal } from "bootstrap";

// https://github.com/rails/rails/pull/32404#commitcomment-30083492
Rails.confirm = (message, element) => {
  const modalElement = document.getElementById("confirmation-modal");
  const modalContent = document.getElementById("confirmation-modal-content");
  const modalOk = document.getElementById("confirmation-modal-ok");
  const modalCancel = document.getElementById("confirmation-modal-cancel");

  modalContent.innerHTML = message;

  const modal = new Modal(modalElement, {
    backdrop: "static",
    keyboard: false,
  });
  modal.show();

  modalOk.addEventListener("click", () => {
    modal.hide();

    modalElement.addEventListener("hidden.bs.modal", function (event) {
      let old = Rails.confirm;
      Rails.confirm = function () {
        return true;
      };
      element.click();
      Rails.confirm = old;
    });
  });

  modalCancel.addEventListener("click", () => {
    modal.hide();
  });

  return false;
};
