import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"]

  closeWithKeyboard(e) {
    if (e.code == "Escape") this.hideModal()
  }

  hideModal(){
    this.element.parentElement.removeAttribute("src");
    this.element.remove()
  }

  submitEnd(e){
  }

  closeBackground(e){
    if (e && this.modalTarget.contains(e.target)) return
    if (e.target.classList.value.match(/\bselect[^\s]+/g) !== null) return
    if (e.target.tagName == 'BODY') return

    this.hideModal()
  }
}
