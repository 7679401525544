import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import * as Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    enable: Boolean,
    url: String,
  };

  connect() {
    if (this.enableValue == true) {
      this.Sortable = Sortable.create(this.element, {
        onEnd: this.end.bind(this),
      });
    }
  }

  end(event) {
    let id = event.item.dataset.id;
    let position = event.newIndex + 1;
    let url = this.urlValue.replace(":id", id) + "?position=" + position;

    Rails.ajax({
      url: url,
      type: "patch",
    });
  }
}
