import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.classList.remove("hidden");
    this.element.parentNode.querySelector("div.spinner-container")?.remove();
    document.addEventListener("turbo:submit-start", () => {
      this.element.classList.add("hidden");
      this.element.parentNode.appendChild(this.spinner());
    });
  }

  spinner() {
    let containerElem = document.createElement("div");
    containerElem.classList.add("spinner-container");
    containerElem.classList.add("text-center");
    containerElem.classList.add("p-3");

    let spinnerElem = document.createElement("div");
    spinnerElem.classList.add("spinner-border");
    spinnerElem.classList.add("text-primary");
    spinnerElem.classList.add("text-center");

    containerElem.appendChild(spinnerElem);

    return containerElem;
  }
}
