import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import "select2";

export default class extends Controller {
  static values = {
    allowClear: false,
    placeholder: 'Select',
  };
  
  connect() {
    $(this.element).select2({
      width: "100%",
      theme: "bootstrap-5",
      allowClear: this.allowClearValue,
      placeholder: this.placeholderValue,
    });

    const fireChangeEvent = () =>
      this.element.dispatchEvent(new Event("change"));

    const unSelectEvent = () => this.element.dispatchEvent(new Event("change"));

    $(this.element).on("select2:select", fireChangeEvent);
    $(this.element).on("select2:unselecting", unSelectEvent);

    $(document).on("turbo:before-cache", () => {
      if($(this.element).data('select2')){
        $(this.element).select2("destroy");
      }
    });
  }
}
