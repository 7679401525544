import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "nextButton"]

  connect() {
    this.formTargets.forEach(form => {
      this.checkForm(form);

      form.addEventListener('change', () => {
        setTimeout(() => this.checkForm(form), 100) 
      });

      form.querySelectorAll('select').forEach(select => {
        select.addEventListener('change', () => {
          setTimeout(() => this.checkForm(form), 100) 
        });
      })
    });

  }

  checkForm(form) {
    const formData = new FormData(form);
    let fieldsFilled = this.checkIfAllFieldsFilled(formData);

    this.nextButtonTarget.style.display = fieldsFilled ? "block" : "none";
  }

  checkIfAllFieldsFilled(formData) {
    let fieldValues = new Map();

    for (let [key, value] of formData.entries()) {
      // Skip empty fields that have already been recorded as filled
      if (fieldValues.has(key) && value === '') {
        continue;
      }

      // skip optional fields
      if (key === 'new_year_bulk_verification_request[default_note]') {
        fieldValues.set(key, true);

        continue;
      }

      if (value === '') {
        fieldValues.set(key, false);

        continue;
      }

      if (fieldValues.has(key) && value !== '') {
        fieldValues.set(key, true);
      }
    }

    return Array.from(fieldValues.values()).every(value => value === true);
  }

  submitAllForms() {
    this.formTargets.forEach(form => {
      form.submit();
    });
  }
}
