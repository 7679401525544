import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  handleChange(e) {
    let id = e.target.id;
    let result = e.target.value;
    if (id == 'insurance_subscriber_has_secondary_insurance'){
      if (result == "true") {
        return this.containerTarget.classList.remove("hidden");
      } else {
        return this.containerTarget.classList.add("hidden");
      }
    }
  }
}
