export class BaseAnimation {
  static DURATION = 250;
  static TARGET_ELEMENT = "TBD";

  /**
   * Event of turbo:load type.
   * Read more about 'event' param: https://turbo.hotwired.dev/reference/events
   */
  static in(event) {
    throw new Error("BaseAnimation->in not implemented!");
  }

  /**
   * Event of turbo:before-render type.
   * Read more about 'event' param: https://turbo.hotwired.dev/reference/events
   */
  static out(event) {
    throw new Error("BaseAnimation->out not implemented!");
  }
}
