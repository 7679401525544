import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import Swal from 'sweetalert2'

export default class extends Controller {
  static values = {
    title: 'Are you sure?',
    body: "You won't be able to revert this!",
    icon: 'warning',
    confirmButtonText: 'Yes'
  }

  display(event) {
    event.preventDefault()
    Swal.fire({
      title: this.titleValue,
      text: this.bodyValue,
      icon: this.iconValue,
      showCancelButton: true,
      confirmButtonColor: '#F87171',
      confirmButtonText: this.confirmButtonTextValue
    }).then((result) => {
      if (result.isConfirmed) {
        Rails.fire(this.element, 'submit')
      }
    })
  }
}