import { BaseAnimation } from "./base";

export class SideBarAnimation extends BaseAnimation {
  static TARGET_ELEMENT = "#side";

  static isElementPresentBeforeRender = null;

  static in(event) {
    if (!SideBarAnimation.shouldAnimateIn(event)) {
      return;
    }

    $(SideBarAnimation.TARGET_ELEMENT).css("display", "none");
    $(SideBarAnimation.TARGET_ELEMENT).fadeIn({
      duration: SideBarAnimation.DURATION,
      queue: false,
    });
  }

  static shouldAnimateIn(event) {
    const isElementPresent =
      document.querySelector(SideBarAnimation.TARGET_ELEMENT) !== null;

    if (isElementPresent && !SideBarAnimation.isElementPresentBeforeRender) {
      SideBarAnimation.isElementPresentBeforeRender = true;
      return true;
    }

    return false;
  }

  static out(event) {
    SideBarAnimation.isElementPresentBeforeRender = document.querySelector(
      SideBarAnimation.TARGET_ELEMENT
    );

    if (!SideBarAnimation.shouldAnimateOut(event)) {
      return;
    }

    $(SideBarAnimation.TARGET_ELEMENT).fadeOut({
      duration: SideBarAnimation.DURATION,
      queue: false,
    });

    return new Promise((sleep) => setTimeout(sleep, SideBarAnimation.DURATION));
  }

  static shouldAnimateOut(event) {
    const willElementBePresent =
      event.detail.newBody.querySelector(SideBarAnimation.TARGET_ELEMENT) !==
      null;

    return !willElementBePresent;
  }
}
