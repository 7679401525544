import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    isNeedsReview: Boolean,
    needsReviewId: String,
    currentPayerId: String,
    shouldRunAutomatically: Boolean
  }

  static targets = [
    "payerIdInput",
    "shouldRunAutomaticallyInput",
    "needsReviewChangeAlertYes",
    "needsReviewChangeAlertNo"
  ]

  connect() {
    this.currentPayerIdValue = this.payerIdInputTarget.value
    this.shouldRunAutomaticallyValue = this.shouldRunAutomaticallyInputTarget.value
    this.updateNoticeVisibility()
  }

  updateShouldRunAutomatically(e) {
    this.shouldRunAutomaticallyValue = e.target.value
    this.updateNoticeVisibility()
  }

  updateCurrentPayerId(e) {
    this.currentPayerIdValue = e.target.value
    this.updateNoticeVisibility()
  }

  updateNoticeVisibility() {
    if (!this.isNeedsReviewValue) return

    if (this.needsReviewIdValue != this.currentPayerIdValue) {
      if (this.shouldRunAutomaticallyValue) {
        this.needsReviewChangeAlertYesTarget.classList.remove("hidden")
        this.needsReviewChangeAlertNoTarget.classList.add("hidden")
      }

      if (!this.shouldRunAutomaticallyValue) {
        this.needsReviewChangeAlertYesTarget.classList.add("hidden")
        this.needsReviewChangeAlertNoTarget.classList.remove("hidden")
      }

      return
    }

    this.needsReviewChangeAlertYesTarget.classList.add("hidden")
    this.needsReviewChangeAlertNoTarget.classList.add("hidden")
  }
}

