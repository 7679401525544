import { Controller } from "@hotwired/stimulus";
import confetti from "canvas-confetti";

export default class extends Controller {
  static values = {
    duration: Number,
  };

  initialize() {
    this.duration = this.durationValue;
    this.animationEnd = Date.now() + this.duration;
    this.defaults = { startVelocity: 30, spread: 360, ticks: 160, zIndex: 0 };
  }

  randomInRange = (min, max) => Math.random() * (max - min) + min;

  celebrate() {
    this.interval = setInterval(() => {
      var timeLeft = this.animationEnd - Date.now();
      var particleCount = 50 * (timeLeft / this.duration);

      if (timeLeft <= 0) return clearInterval(this.interval);

      confetti(
        Object.assign({}, this.defaults, {
          particleCount,
          origin: { x: this.randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, this.defaults, {
          particleCount,
          origin: { x: this.randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
  }

  connect = () => this.celebrate();
  disconnect = () => clearInterval(this.interval);
}
