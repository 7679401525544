import { Controller } from "@hotwired/stimulus";

const LA_COORDINATES = { lat: 33.94332743212616, lng: -117.56279986253173 };

const LA_BOUNDS = {
  north: 34.600785488579646,
  south: 33.36716080189239,
  west: -118.84478534826027,
  east: -117.78979895364465,
};

const MAP_OPTIONS = {
  mapId: "eb82d3b68cf0c337",
  zoom: 10,
  center: LA_COORDINATES,
  gestureHandling: "greedy",
  disableDefaultUI: true,
  minZoom: 10,
  maxZoom: 18,
  restriction: {
    latLngBounds: LA_BOUNDS,
    strictBounds: true,
  },
};

export default class extends Controller {
  static values = {
    markers: Array,
  };

  initMap = () => {
    const map = this.setMap();

    this.setMarkers(map);
    this.setBounds(map);
  };

  setMap = () => {
    return new google.maps.Map(this.element, MAP_OPTIONS);
  };

  setMarkers = (map) => {
    this.markersValue.map((value) => {
      let marker = this.setMarker(map, value);
      this.setInfoWindow(map, marker, value);
    });
  };

  setMarker = (map, { position, label }) => {
    return new google.maps.Marker({
      position,
      label,
      animation: google.maps.Animation.DROP,
      map,
    });
  };

  setInfoWindow = (map, marker, { infoWindowContent }) => {
    let infoWindow = new google.maps.InfoWindow({
      content: infoWindowContent,
    });

    marker.addListener("click", () => {
      infoWindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });
    });

    google.maps.event.addListener(map, "click", () => {
      infoWindow.close();
    });

    return infoWindow;
  };

  setBounds = (map) => {
    map.fitBounds(this.bounds());
  };

  bounds() {
    let latLongBounds = new google.maps.LatLngBounds();
    this.markersValue.map(({ position }) => latLongBounds.extend(position));

    return latLongBounds;
  }
}
