import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

const INTERNAL_DATE_FORMAT = "Y-m-d";
const INTERNAL_TIME_FORMAT = "H:i";

const DATE_FORMAT = "m/d/Y";
const TIME_FORMAT = "h:i K";

const MINUTE_INCREMENT = 15;

const minDate = new Date();
minDate.setHours(0);
minDate.setMinutes(0);
minDate.setSeconds(0);
minDate.setMilliseconds(0);

const enforceMinuteIncrement = (_arg1, _arg2, picker) => {
  const date = picker.latestSelectedDateObj;
  const minutes = date?.getMinutes();

  if (minutes && minutes % MINUTE_INCREMENT !== 0) {
    date.setMinutes(MINUTE_INCREMENT * Math.round(minutes / MINUTE_INCREMENT));
    picker.setDate(date);
  }
};

export default class extends Controller {
  _getOptions() {
    const type = this.element.getAttribute("data-type");

    switch (type) {
      case "time":
        return {
          enableTime: true,
          noCalendar: true,

          altFormat: TIME_FORMAT,
        };
      case "date":
        return {
          altFormat: DATE_FORMAT,
        };

      default:
        return {
          enableTime: true,

          altFormat: `${DATE_FORMAT}, ${TIME_FORMAT}`,
        };
    }
  }

  handleChange = (_arg1, _arg2, picker) => {
    enforceMinuteIncrement(_arg1, _arg2, picker);
    this.element.dispatchEvent(new Event("change"));
  };

  connect() {
    let flatpicker = flatpickr(this.element, {
      altInput: true,
      minuteIncrement: MINUTE_INCREMENT,
      onChange: this.handleChange,
      dateFormat: `${INTERNAL_DATE_FORMAT}\\T${INTERNAL_TIME_FORMAT}`,
      ...this._getOptions(),
    });

    $(document).on("turbo:before-cache", () => {
      flatpicker.destroy();
    });
  }
}
