import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  listener(e) {
    e.preventDefault();
    e.returnValue = "Are you sure? All changes will be lost";
  }

  connect() {
    window.addEventListener("beforeunload", this.listener);
  }

  disconnect() {
    window.removeEventListener("beforeunload", this.listener);
  }
}
