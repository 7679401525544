import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loader", "content"];

  disableButton(e) {
    e.currentTarget.setAttribute("disabled", "");
    this.loaderTarget.classList.remove("hidden");
    this.contentTarget.classList.add("hidden");
  }
}