import { BaseAnimation } from "./base";

export class MainBodyAnimation extends BaseAnimation {
  static TARGET_ELEMENT = "#main";
  static DURATION = 150;

  static in(event) {
    $(MainBodyAnimation.TARGET_ELEMENT).css("display", "none");
    $(MainBodyAnimation.TARGET_ELEMENT).fadeIn({
      duration: MainBodyAnimation.DURATION,
      queue: false,
    });
  }

  static out(event) {
    $(MainBodyAnimation.TARGET_ELEMENT).fadeOut({
      duration: MainBodyAnimation.DURATION,
      queue: false,
    });

    return new Promise((sleep) =>
      setTimeout(sleep, MainBodyAnimation.DURATION)
    );
  }
}
