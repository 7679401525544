import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["file", "results"];

  connect() {
    console.log("mounted");
    console.log(this.fileTarget);
  }

  handleChange(e) {
    console.log('changed')
    let result = e.target.value;
    if (result == "true") {
      return this.fileTarget.classList.remove("hidden");
    } else {
      return this.fileTarget.classList.add("hidden");
    }
  }
}
