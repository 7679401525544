import { BookBodyAnimation } from "./book";
import { MainBodyAnimation } from "./main";
import { SideBarAnimation } from "./sidebar";

let isLastEventRender = true;

const animationsIn = [
  // MainBodyAnimation.in,
  SideBarAnimation.in,
  BookBodyAnimation.in,
];
const animationsOut = [
  // MainBodyAnimation.out,
  SideBarAnimation.out,
  BookBodyAnimation.out,
];

let skipAnimation = false;

document.addEventListener("turbo:click", (event) => {
  skipAnimation = !!(
    event.target?.getAttribute("data-skip-animation") === "true"
  );
});

document.addEventListener("turbo:load", (event) => {
  isLastEventRender = false;

  if (skipAnimation) {
    return;
  }

  animationsIn.forEach((animationIn) => {
    animationIn(event);
  });
});

document.addEventListener("turbo:before-render", async (event) => {
  if (skipAnimation || isLastEventRender) {
    return;
  }

  isLastEventRender = true;
  event.preventDefault();

  await Promise.all(
    animationsOut.map((animationOut) => {
      return animationOut(event);
    })
  );

  event.detail.resume();
});
