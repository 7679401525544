import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("connected hello controller");
    this.element.textContent = "Hello World!";
  }

  disconnect() {
    console.log("disconnected hello controller");
  }
}
