// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "@hotwired/turbo-rails";
import "channels";
import "./animations";
import "../controllers";
import "./confirm";
import "./google_services";
import "./date_time_input_formatter";

require("trix");
require("@rails/actiontext");

Rails.start();
ActiveStorage.start();

import jquery from 'jquery';
window.$ = window.jquery = jquery;