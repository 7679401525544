import { Controller } from "@hotwired/stimulus";
import confetti from "canvas-confetti";

export default class extends Controller {
  static values = {
    numberOfPractitioners: Number,
    finalTargetId: String,
  };

  STEPS = [
    {
      label: "Finding physical therapists near you...",
      duration: 0,
    },
    {
      label: `${this.numberOfPractitionersValue} physical therapists found!`,
      duration: 2,
    },
    {
      label: "Finding therapists specializes in your needs...",
      duration: 4,
    },
    {
      label: "A match has been found!",
      duration: 6,
    },
  ];

  connect = () => {
    document.getElementById(this.finalTargetIdValue).classList.add("d-none");
    this.STEPS.map((step, index) => {
      setTimeout(() => {
        this.current_step = step;
        this.element.innerHTML = this.setStepHTML({
          ...this.current_step,
          percent: ((index + 1) / this.STEPS.length) * 100,
        });
      }, step.duration * 1000);
    });

    setTimeout(() => {
      this.element.innerHTML = "";
      document
        .getElementById(this.finalTargetIdValue)
        .classList.remove("d-none");
      confetti({
        particleCount: 130,
        spread: 130,
        origin: { y: 0.6 },
      });
    }, 8000);
  };

  setStepHTML = (step) => `
    <div class="d-flex justify-content-center">
      <div class='col-6 text-center'>
        <h3 class="mb-5 pb-5">${step.label}</h3>
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: ${step.percent}%"></div>
        </div>
      </div>
    </div>
  `;
}
