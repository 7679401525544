const patchContainerWidthForDate = (classNames) =>
  classNames.map((name) =>
    document
      .querySelectorAll(`.form-control.mx-1.${name}`)
      .forEach((el) => el.parentElement.classList.add("w-100"))
  );

document.addEventListener("turbo:load", () => {
  patchContainerWidthForDate(["datetime", "time", "date"]);
});
