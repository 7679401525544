import { Controller } from "@hotwired/stimulus";
import Slider from "bootstrap-slider";

export default class extends Controller {
  static values = {
    start: Number,
    min: Number,
    max: Number,
    tooltip: String
  };

  connect() {
    let tickOptionLabels = Array.from(
      { length: this.maxValue - (this.minValue - 1) }, (_, i) => i + this.minValue
    )
    let slider = new Slider(this.element, {
      min: this.minValue,
      max: this.maxValue,
      ticks: tickOptionLabels,
      ticks_labels: tickOptionLabels,
      tooltip: this.tooltipValue,
    });
    slider.setValue(this.startValue)
  }
}
