import { Controller } from "@hotwired/stimulus";
import * as Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    syncAttemptStatus: String,
    redirectPath: String,
    modalTitle: String,
    frameId: String
  }

  static targets = ["form"]

  connect() {
    if (this.syncAttemptStatusValue == 'completed') {
      this.stopTimer();
      
      if (this.hasFormTarget) {
        Rails.fire(this.formTarget, 'submit')
      } else {
        this._timer = setInterval(()=> {
          const frame = document.getElementById(this.frameIdValue);
          frame.src = null
          frame.src = this.redirectPathValue
        }, 4000)
      }
    } else {
      this._timer = setInterval(()=> {
        const frame = document.getElementById(this.frameIdValue);
        const { src } = frame
        frame.src = null
        frame.src = src
      }, 4000)
    }
  }

  stopTimer() {
    const timer = this._timer;

    if (!timer) return;

    clearInterval(timer);
  }

  disconnect() {
    this.stopTimer();
  }
}
