import { BaseAnimation } from "./base";

export class BookBodyAnimation extends BaseAnimation {
  static TARGET_ELEMENT = "#book";
  static DURATION = 150;

  static in(event) {
    $(BookBodyAnimation.TARGET_ELEMENT).css("display", "none");
    $(BookBodyAnimation.TARGET_ELEMENT).fadeIn({
      duration: BookBodyAnimation.DURATION,
      queue: false,
    });
  }

  static out(event) {
    $(BookBodyAnimation.TARGET_ELEMENT).fadeOut({
      duration: BookBodyAnimation.DURATION,
      queue: false,
    });

    return new Promise((sleep) =>
      setTimeout(sleep, BookBodyAnimation.DURATION)
    );
  }
}
