import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
import "daterangepicker"
import * as Rails from "@rails/ujs"

export default class extends Controller {
  connect() {
    let daterange = this.element
    let daterangeId = $(daterange).attr('id')
    let daterangePicker = $(daterange).daterangepicker({
      autoUpdateInput: false,
      locale: {
        cancelLabel: 'Clear'
      },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Last Year': [moment().subtract(1, 'year'), moment()],
      }
    })

    daterangePicker.on('apply.daterangepicker', function(ev, picker) {
      let gteq = document.getElementById(`${daterangeId}_gteq`)
      let lteq = document.getElementById(`${daterangeId}_lteq`)
      $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'))
      if (gteq != null) { gteq.value = picker.startDate.format('YYYY-MM-DD')  }
      if (lteq != null) { lteq.value = picker.endDate.format('YYYY-MM-DD') }
      Rails.fire(daterange.closest('form'), 'submit')
    })

    daterangePicker.on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('')
      document.getElementById(`${daterangeId}_gteq`).value = ''
      document.getElementById(`${daterangeId}_lteq`).value = ''
      Rails.fire(daterange.closest('form'), 'submit')
    })
  }

  handleClear(event) {
    let daterange = this.element
    let daterangeId = $(daterange).attr('id')
    if (this.element.value == ''){
      document.getElementById(`${daterangeId}_gteq`).value = ''
      document.getElementById(`${daterangeId}_lteq`).value = ''
      Rails.fire(daterange.closest('form'), 'submit')
    }
  }
}