import { Controller } from "@hotwired/stimulus";
import * as Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    syncAttemptStatus: String,
    redirectPath: String,
    modalTitle: String,
  }

  static targets = ["form"]

  connect() {
    this.changeModalTitle();
    
    if (this.syncAttemptStatusValue == 'completed') {
      this.stopTimer();
      if (this.hasFormTarget) {
        Rails.fire(this.formTarget, 'submit')
      } else {
        this._timer = setInterval(()=> {
          const frame = document.getElementById("verification_request_wizard");
          frame.src = null
          frame.src = this.redirectPathValue
        }, 4000)
      }
    } else {
      this._timer = setInterval(()=> {
        const frame = document.getElementById("verification_request_wizard");
        const { src } = frame
        frame.src = null
        frame.src = src
      }, 4000)
    }
  }

  stopTimer() {
    const timer = this._timer;

    if (!timer) return;

    clearInterval(timer);
  }

  changeModalTitle() {
    const modalTitle = document.querySelector("#modal-header span");
    this.previousModalTitleValue = modalTitle.innerHTML;
    modalTitle.innerHTML = this.modalTitleValue;
  }

  changeModalTitleBack() {
    const modalTitle = document.querySelector("#modal-header span");
    modalTitle.innerHTML = this.previousModalTitleValue;
  }

  disconnect() {
    this.stopTimer();
    this.changeModalTitleBack();
  }
}
