import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    loadingComponent: String,
  }

  connect() {
    this.loadingComponent = document.createElement("div")
    this.loadingComponent.innerHTML = this.loadingComponentValue
    document.addEventListener('turbo:submit-end', this.submitEnd.bind(this));
  }

  submit() {
    const fileInput = this.element.querySelector('input[type="file"]');

    if (fileInput && fileInput.value) {
      // TODO: this is hardcoded
      document.querySelector('#new_year_bulk_verification_request > div').style.display = "none";
      document.querySelector('#new_year_bulk_verification_request').appendChild(this.loadingComponent);
    } else {
      console.error("File input is empty. Please select a file.");
    }
  }

  submitEnd() {
    document.querySelector('#new_year_bulk_verification_request > div').style.display = "block";
    const parent = document.querySelector('#new_year_bulk_verification_request');
    if (parent.contains(this.loadingComponent)) {
      parent.removeChild(this.loadingComponent);
    }
  }
}