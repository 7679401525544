import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    loadingComponent: String,
    parentElementId: String,
  }

  connect() {
    this.loadingComponent = document.createElement("div")
    this.loadingComponent.innerHTML = this.loadingComponentValue 
  }

  submit() {
    if (this.parentElementIdValue) {
      let parentElement = document.getElementById(this.parentElementIdValue);
      let containerToHide = document.querySelector(`#${this.parentElementIdValue} > div`);

      containerToHide.style.display = "none";  
      parentElement.appendChild(this.loadingComponent);
    } else {
      this.element.style.display = "none"; 
      this.element.parentElement.appendChild(this.loadingComponent);
    }    
  }
}