import { Controller } from "@hotwired/stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

export default class extends Controller {
  static values = {
    items: Array,
    initialView: String,
    allowedViews: Array,
    showTitle: Boolean,
    showNavigation: Boolean,
  };

  connect() {
    new Calendar(this.element, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      initialView: this.initialViewValue,
      headerToolbar: {
        center: this.showTitleValue == false ? "" : "title",
        left: this.showNavigationValue == false ? "" : "prev,next today",
        right: this.allowedViewsValue.toString(),
      },
      eventClassNames: "bg-primary p-3",
      firstDay: 1,
      eventBorderColor: "transparent",
      height: "720px",
      weekends: false,
      allDaySlot: false,
      slotMinTime: "7:00am",
      slotMaxTime: "20:00pm",
      events: this.itemsValue,
      views: {
        week: {},
      },
      dayHeaderFormat: { weekday: "long" },
    }).render();
  }
}
