import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.subscriberInputs = this.element.querySelectorAll(".subscriber-input");

    this.subscriberInputs.forEach(input => {
      const checkbox = input.querySelector('input[type="checkbox"]');

      if (checkbox) {
        checkbox.addEventListener('change', this.toggleCheckbox.bind(this));

        this.syncCheckboxChildren(checkbox)
      }
    });
  }

  syncCheckboxChildren(checkbox) {
    const parent = checkbox.parentElement.parentElement;
    const radios = parent.querySelectorAll('input[type="radio"]');
    const destroyInput = parent.querySelector('.subscriber-input-destroy');
    const radioGroup = parent.querySelector('.subscriber-radio-group');

    radios.forEach(radio => { radio.disabled = !checkbox.checked; });
    
    if (parent.dataset.persisted === 'true') destroyInput.disabled = checkbox.checked;

    if (checkbox.checked) {
      radioGroup.classList.remove('hidden');
    } else {
      radioGroup.classList.add('hidden');
    }
  }

  toggleCheckbox(event) {
    const checkbox = event.target;
    this.syncCheckboxChildren(checkbox);
  }
}
