import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";
import * as Rails from "@rails/ujs";

export default class extends Controller {

  initialize() {
    this.submit = this.submit.bind(this);
  }

  connect() {
    // remove search button on form
    this.element.querySelectorAll('[type="submit"]')
      .forEach((el) => el.remove())

    let inputs = this.element.querySelectorAll('input')
    inputs.forEach((el) => {
      if (el.type == 'search' || (el.type == 'text' && !el.classList.contains('date-range')) ){
        el.addEventListener('keyup', debounce(this.submit, 1000));
      }else if(el.type == 'date'){
        el.addEventListener('change', debounce(this.submit, 1000));
      }else{
        if (!el.classList.contains('date-range')) {
          el.addEventListener('click', this.submit);
        }
      }
    });
  }

  submit() {
    Rails.fire(this.element, "submit");
  }
}
