import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import { Modal } from "bootstrap";

export default class extends Controller {
  static values = {
    input: String,
  };

  copy(e) {
    e.preventDefault();
    let input = document.createElement('input');
    input.setAttribute('value', this.inputValue);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    const modalElement = document.getElementById("confirmation-modal");
    const modalContent = document.getElementById("confirmation-modal-content");
    const modalOk = document.getElementById("confirmation-modal-ok");
    const modalCancel = document.getElementById("confirmation-modal-cancel");
    modalCancel.style.display = 'none';
    modalContent.innerHTML = `${this.inputValue} was copied to your clipboard`;
  
    const modal = new Modal(modalElement, {
      backdrop: "static",
      keyboard: false,
    });
    modal.show();
  
    modalOk.addEventListener("click", () => {
      modal.hide();
    });
    return false;
  }
}

