const initializePlacesAutocomplete = () => {
  window.initAutocomplete = function () {
    const event = new Event("google-maps-callback", {
      bubbles: true,
      cancelable: true,
    });
    window.dispatchEvent(event);
  };
};

document.addEventListener("turbo:load", () => {
  initializePlacesAutocomplete();
});
