import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    if (this.element.querySelector('#box-link')){
      this.element.querySelector('#box-link').remove();
    }
  }

  connect() {
    this.element.childNodes[0].classList.add("clamped-text-2");
    this.element.appendChild(this.boxLink());
  }

  boxLink() {
    const boxLink = document.createElement("div");
    const link = `<div class="see-more flex w-fit pt-1 text-orange-300" data-action="mouseover->practitioner#seeMore">
                    Show more
                  </div>
                  <div class="show-less w-fit hidden text-orange-300" data-action="mouseover->practitioner#showLess">
                    Hide
                  </div>`;

    boxLink.setAttribute("id", "box-link");
    boxLink.innerHTML = link;
    return boxLink;
  }

  seeMore() {
    this.element.childNodes[0].classList.remove("clamped-text-2");
    this.element.getElementsByClassName("see-more")[0].classList.add("hidden");
    this.element
      .getElementsByClassName("show-less")[0]
      .classList.remove("hidden");
    this.element.getElementsByClassName("show-less")[0].classList.add("flex");
  }

  showLess() {
    this.element.childNodes[0].classList.add("clamped-text-2");
    this.element.getElementsByClassName("show-less")[0].classList.add("hidden");
    this.element
      .getElementsByClassName("see-more")[0]
      .classList.remove("hidden");
  }
}
