import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["links", "template"];

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields";
  }

  add_association(event) {
    event.preventDefault();

    var content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );
    this.linksTarget.insertAdjacentHTML("beforebegin", content);
  }

  add_insurance(event) {
    event.preventDefault();

    let nestedFields = this.element.querySelectorAll(".nested-fields")
    let nestedFieldsCount = nestedFields.length
    let kind = (nestedFieldsCount == 1) ? 'secondary' : 'tertiary'
    var content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    ).replace(/INSURANCE_KIND/g, kind);
    this.linksTarget.insertAdjacentHTML("beforebegin", content);

    this.setButtonText();
    this.setInsuranceKind(kind);
  }

  remove_association(event) {
    event.preventDefault();

    let wrapper = event.target.closest("." + this.wrapperClass);

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = "none";
    }
  }

  remove_insurance(event){
    this.remove_association(event);
    this.setButtonText();
  }

  setButtonText() {
    this.linksTarget.classList.remove("hidden");
    let nestedFieldsCount = this.element.querySelectorAll(".nested-fields").length;
    let btnText = 'Add Secondary';
    if (nestedFieldsCount == 1){
      btnText = 'Add Secondary'
    }else if(nestedFieldsCount == 2){
      btnText = 'Add Tertiary'
    }else if(nestedFieldsCount == 3){
      this.linksTarget.classList.add("hidden");
    }

    this.linksTarget.querySelector(".font-semibold").innerHTML = btnText;
  }

  setInsuranceKind(kind) {
    let nestedFields = this.element.querySelectorAll(".nested-fields");
    let lastChild = nestedFields[nestedFields.length - 1];
    lastChild.querySelector("#insurance_subscriber_"+kind+"_kind").value = kind;
  }
}
